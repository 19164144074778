$(document).on('turbo:load', () => {
  if ($('#events-event-session').length !== 0) {
    const sessionMaxTickets = $(document).find('.session_max_tickets').val()
    let block = false
    const calculateTotals = () => {
      let totalTickets = 0
      let totalPrice = 0
      const $button = $('.event_session_bottom__submit')
      $('.ticket_count').each(function () {
        const ticketCount = parseFloat($(this).text())
        if (sessionMaxTickets) {
          // the == needs to be 2 = not 3 because is checking if number match the number in string
          if ((totalTickets + ticketCount) == (sessionMaxTickets)) {
            block = true
            $(document).find('.increment').removeClass('bg-dark-gray').addClass('bg-light-gray')
            $(document).find('.tooltip').attr('data-active', 'true')
          } else if ((totalTickets + ticketCount) > (sessionMaxTickets)) {
            return
          }
        }
        const ticketPrice = $(this).parents('.render_typology').find('.ticket_price').text()
        totalTickets += ticketCount
        totalPrice += parseFloat(ticketPrice.replace(',', '.')).toFixed(2) * ticketCount
      })
      $('.total_tickets').text(totalTickets)

      $('.total_price').text(totalPrice.toFixed(2).replace('.', ','))

      if (totalTickets >= 1) {
        $button.prop('disabled', false)
      } else {
        $button.prop('disabled', true)
      }
    }

    $('.increment').on('click', function () {
      const count = $(this).parent().find('.ticket_count')
      const hiddenCount = $(this).parent().find('.hidden_count')
      const availability = $(this).parent().find('.hidden_availability')
      const $button = $('.event_session_bottom__submit')
      const typologyMaxTickets = $(this).parent().find('.typology_max_tickets').val()
      const newValue = (parseInt(count.text()) + 1)
      if (availability.val() === 'true' && block === false) {
        if (typologyMaxTickets) {
          if (newValue > typologyMaxTickets) {
            return
          } else if (newValue == typologyMaxTickets) {
            $(this).parent().find('.increment').removeClass('bg-dark-gray').addClass('bg-light-gray')
            $(this).parent().find('.tooltip').attr('data-active', 'true')
          }
        }
        count.text(`${parseInt(count.text()) + 1}`)
        hiddenCount.val(count.text())

        if (count.text() === '1') {
          $(this).parent().find('.decrement').removeClass('bg-light-gray').addClass('bg-dark-gray')
          $button.prop('disabled', false)
        }

        calculateTotals()
      }
    })

    $('.decrement').on('click', function () {
      const count = $(this).parent().find('.ticket_count')
      const hiddenCount = $(this).parent().find('.hidden_count')

      if (parseInt(count.text()) > 0) {
        count.text(`${parseInt(count.text()) - 1}`)
        hiddenCount.val(count.text())
        const $button = $('.event_session_bottom__submit')

        if (count.text() === '0') {
          $(this).removeClass('bg-dark-gray').addClass('bg-light-gray')
          $button.prop('disabled', true)
        }

        if ($(this).parent().find('.increment').hasClass('bg-dark-gray')) {
          $(this).parent().find('.increment').removeClass('bg-dark-gray').addClass('bg-dark-gray')
          $(this).parent().find('.tooltip').attr('data-active', 'false')
        }
        if (block) {
          block = false
          $('.ticket_count').each(function () {
            const availability = $(this).parent().find('.hidden_availability')
            if (availability.val() === 'true') {
              $(this).parent().find('.increment').removeClass('bg-light-gray').addClass('bg-dark-gray')
              $(document).find('.tooltip').attr('data-active', 'false')
            }
          })
        }

        calculateTotals()
      }
    })
  }
})
