/**
 * File generated by js-routes 2.1.2
 * Based on Rails 5.2.6 routes of Ticketline::Application
 */
const __jsr = ((that) => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const Root = that;
    const isBroswer = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define(routes) {
                Utils.namespace(Root, null, routes);
            },
            isSupported() {
                return !!Root;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {};
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            const query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBroswer && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        namespace(object, namespace, routes) {
            const parts = (namespace === null || namespace === void 0 ? void 0 : namespace.split(".")) || [];
            if (parts.length === 0) {
                return routes;
            }
            for (let index = 0; index < parts.length; index++) {
                const part = parts[index];
                if (index < parts.length - 1) {
                    object = object[part] || (object[part] = {});
                }
                else {
                    return (object[part] = routes);
                }
            }
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})(this);
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /accept_cookies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_cookies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"accept_cookies"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /accept_terms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_terms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"accept_terms"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/advertisements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_advertisement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"advertisements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/advertisements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_advertisements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"advertisements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/carousel_items/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_carousel_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"carousel_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/carousel_items(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_carousel_items_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"carousel_items"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/consents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_consent_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"consents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/consents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_consents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"consents"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/dynamic_forms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_dynamic_form_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dynamic_forms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dynamic_forms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_dynamic_forms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dynamic_forms"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/event_lists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_event_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"event_lists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/event_lists/:event_list_id/custom_thumbnails/:id(.:format)
 * @param {any} event_list_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_event_list_custom_thumbnail_path = __jsr.r({"event_list_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"event_lists"],[2,[7,"/"],[2,[3,"event_list_id"],[2,[7,"/"],[2,[6,"custom_thumbnails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/event_lists/:event_list_id/custom_thumbnails(.:format)
 * @param {any} event_list_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_event_list_custom_thumbnails_path = __jsr.r({"event_list_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"event_lists"],[2,[7,"/"],[2,[3,"event_list_id"],[2,[7,"/"],[2,[6,"custom_thumbnails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/event_lists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_event_lists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"event_lists"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/footer_pages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_footer_page_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"footer_pages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/footer_pages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_footer_pages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"footer_pages"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/messages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_message_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/messages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"messages"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/seats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_seats_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"seats"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/select2/event_location_date_sessions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_select2_event_location_date_sessions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"select2"],[2,[7,"/"],[2,[6,"event_location_date_sessions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/select2/event_location_dates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_select2_event_location_dates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"select2"],[2,[7,"/"],[2,[6,"event_location_dates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/select2/event_locations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_select2_event_locations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"select2"],[2,[7,"/"],[2,[6,"event_locations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/select2/events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_select2_events_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"select2"],[2,[7,"/"],[2,[6,"events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/select2/events_slug(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_select2_events_slug_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"select2"],[2,[7,"/"],[2,[6,"events_slug"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/utms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_utm_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"utms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/utms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_utms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"utms"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/widget_permissions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_widget_permission_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"widget_permissions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/widget_permissions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_widget_permissions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"widget_permissions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/auth/validate_token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_auth_validate_token_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"validate_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/auth/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /calendar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const calendar_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"calendar"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /cart(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cart_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cart"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /cart/add(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cart_add_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cart"],[2,[7,"/"],[2,[6,"add"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cart/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cart_cancel_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cart"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cart/data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cart_data_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cart"],[2,[7,"/"],[2,[6,"data"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cart/insurance(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cart_insurance_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cart"],[2,[7,"/"],[2,[6,"insurance"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cart/payment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cart_payment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cart"],[2,[7,"/"],[2,[6,"payment"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cart/payment_webhook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cart_payment_webhook_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cart"],[2,[7,"/"],[2,[6,"payment_webhook"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cart(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cart_processing_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cart"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /cart/promo_code(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cart_promo_code_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cart"],[2,[7,"/"],[2,[6,"promo_code"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cart/remove/:ticket_id(.:format)
 * @param {any} ticket_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cart_remove_path = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"cart"],[2,[7,"/"],[2,[6,"remove"],[2,[7,"/"],[2,[3,"ticket_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /cart/remove_event/:tickets(.:format)
 * @param {any} tickets
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cart_remove_event_path = __jsr.r({"tickets":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"cart"],[2,[7,"/"],[2,[6,"remove_event"],[2,[7,"/"],[2,[3,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /cart/summary(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cart_summary_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cart"],[2,[7,"/"],[2,[6,"summary"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cart/update_discount(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cart_update_discount_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cart"],[2,[7,"/"],[2,[6,"update_discount"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const categories_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"categories"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /categories/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"categories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /change_locale(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_locale_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"change_locale"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /contact_us(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contact_us_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contact_us"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /cookies_policy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cookies_policy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cookies_policy"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admins/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_admin_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/auth/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_api_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user/messages/destroy_multiple(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_multiple_user_messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"destroy_multiple"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /districts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const districts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"districts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /user/tickets/event_session/:event_session_id/download(.:format)
 * @param {any} event_session_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_user_tickets_path = __jsr.r({"event_session_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"event_session"],[2,[7,"/"],[2,[3,"event_session_id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /events/:id/dynamic_forms(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dynamic_forms_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"dynamic_forms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/advertisements/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_advertisement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"advertisements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/carousel_items/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_carousel_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"carousel_items"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/consents/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_consent_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"consents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dynamic_forms/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_dynamic_form_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dynamic_forms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/event_lists/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_event_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"event_lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/event_lists/:event_list_id/custom_thumbnails/:id/edit(.:format)
 * @param {any} event_list_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_event_list_custom_thumbnail_path = __jsr.r({"event_list_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"event_lists"],[2,[7,"/"],[2,[3,"event_list_id"],[2,[7,"/"],[2,[6,"custom_thumbnails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/footer_pages/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_footer_page_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"footer_pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/widget_permissions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_widget_permission_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"widget_permissions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /venues/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_venue_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"venues"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /events/:slug(.:format)
 * @param {any} slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const event_path = __jsr.r({"slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"events"],[2,[7,"/"],[2,[3,"slug"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/carousel_items/events/:event_id(.:format)
 * @param {any} event_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const event_admin_carousel_items_path = __jsr.r({"event_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"carousel_items"],[2,[7,"/"],[2,[6,"events"],[2,[7,"/"],[2,[3,"event_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /events/:slug/event_session(.:format)
 * @param {any} slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const event_session_path = __jsr.r({"slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"events"],[2,[7,"/"],[2,[3,"slug"],[2,[7,"/"],[2,[6,"event_session"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /events/:slug/show_async(.:format)
 * @param {any} slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const event_show_async_path = __jsr.r({"slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"events"],[2,[7,"/"],[2,[3,"slug"],[2,[7,"/"],[2,[6,"show_async"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const events_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"events"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /faq(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const faq_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"faq"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /health(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const health_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"health"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /health_sentry(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const health_sentry_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"health_sentry"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /help(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const help_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"help"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /user/tickets/history(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const history_user_tickets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"history"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /index(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"index"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /nearby_events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const nearby_events_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"nearby_events"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/advertisements/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_advertisement_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"advertisements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/carousel_items/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_carousel_item_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"carousel_items"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/consents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_consent_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"consents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dynamic_forms/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_dynamic_form_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dynamic_forms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/event_lists/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_event_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"event_lists"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/event_lists/:event_list_id/custom_thumbnails/new(.:format)
 * @param {any} event_list_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_event_list_custom_thumbnail_path = __jsr.r({"event_list_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"event_lists"],[2,[7,"/"],[2,[3,"event_list_id"],[2,[7,"/"],[2,[6,"custom_thumbnails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/footer_pages/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_footer_page_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"footer_pages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/messages/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_message_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/utms/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_utm_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"utms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/widget_permissions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_widget_permission_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"widget_permissions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/auth/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /venues/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_venue_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"venues"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /online_dispute_resolution(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const online_dispute_resolution_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"online_dispute_resolution"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /privacy_security(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const privacy_security_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"privacy_security"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /promotional_services(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const promotional_services_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"promotional_services"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /promotors/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const promotor_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"promotors"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /render_async_helper(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const render_async_helper_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"render_async_helper"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /render_async_list_helper(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const render_async_list_helper_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"render_async_list_helper"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /swagger
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rswag_api_path = __jsr.r({}, [2,[7,"/"],[6,"swagger"]]);

/**
 * Generates rails route to
 * /swagger
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rswag_ui_path = __jsr.r({}, [2,[7,"/"],[6,"swagger"]]);

/**
 * Generates rails route to
 * /sales_points(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sales_points_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sales_points"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /seats/add_marked_seat(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const seats_add_marked_seat_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"seats"],[2,[7,"/"],[2,[6,"add_marked_seat"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /seats/update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const seats_update_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"seats"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /seats/zone_map(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const seats_zone_map_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"seats"],[2,[7,"/"],[2,[6,"zone_map"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /select2/countries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const select2_countries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"select2"],[2,[7,"/"],[2,[6,"countries"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /select2/districts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const select2_districts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"select2"],[2,[7,"/"],[2,[6,"districts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/messages/select2_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const select2_emails_admin_messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"select2_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /select2/venues(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const select2_venues_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"select2"],[2,[7,"/"],[2,[6,"venues"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/show_buyer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_buyer_admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"show_buyer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user/tickets/event_session/:event_session_id(.:format)
 * @param {any} event_session_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_user_tickets_path = __jsr.r({"event_session_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"event_session"],[2,[7,"/"],[2,[3,"event_session_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_path = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]);

/**
 * Generates rails route to
 * /sortable/reorder(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sortable_reorder_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sortable"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /terms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const terms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"terms"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /terms_conditions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const terms_conditions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"terms_conditions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /magazines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tl_magazines_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"magazines"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /user/categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_categories_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"categories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user/categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_categories_update_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"categories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user/districts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_districts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"districts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user/districts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_districts_update_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"districts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /omniauth/facebook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_facebook_omniauth_authorize_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"omniauth"],[2,[7,"/"],[2,[6,"facebook"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /omniauth/facebook/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_facebook_omniauth_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"omniauth"],[2,[7,"/"],[2,[6,"facebook"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user/favourites(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_favourites_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"favourites"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user/follow/event/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_follow_event_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"follow"],[2,[7,"/"],[2,[6,"event"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /user/follow/promotor/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_follow_promotor_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"follow"],[2,[7,"/"],[2,[6,"promotor"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /user/follow/venue/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_follow_venue_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"follow"],[2,[7,"/"],[2,[6,"venue"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /user/following(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_following_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"following"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /omniauth/google_oauth2(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_google_oauth2_omniauth_authorize_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"omniauth"],[2,[7,"/"],[2,[6,"google_oauth2"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /omniauth/google_oauth2/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_google_oauth2_omniauth_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"omniauth"],[2,[7,"/"],[2,[6,"google_oauth2"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user/invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user/messages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_message_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user/messages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"messages"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user/preferences(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_preferences_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"preferences"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user/preferences(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_preferences_update_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"preferences"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user/profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"profile"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user/profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_profile_update_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"profile"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_tickets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /omniauth/twitter(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_twitter_omniauth_authorize_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"omniauth"],[2,[7,"/"],[2,[6,"twitter"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /omniauth/twitter/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_twitter_omniauth_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"omniauth"],[2,[7,"/"],[2,[6,"twitter"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/utms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const utms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"utms"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/dynamic_forms/:id/validate_payload(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const validate_payload_admin_dynamic_form_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dynamic_forms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"validate_payload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /venues/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const venue_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"venues"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /venues/:venue_id/rooms/:id(.:format)
 * @param {any} venue_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const venue_room_path = __jsr.r({"venue_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"venues"],[2,[7,"/"],[2,[3,"venue_id"],[2,[7,"/"],[2,[6,"rooms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /venues(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const venues_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"venues"],[1,[2,[8,"."],[3,"format"]]]]]);

