import { Controller } from '@hotwired/stimulus'
import 'intl-tel-input/build/css/intlTelInput.css'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/js/utils.js'

export default class extends Controller {
  static targets = ["phoneNumber"]

  connect () {
    const input = this.phoneNumberTarget
    const int = intlTelInput(input, {
      nationalMode: true,
      autoHideDialCode: false,
      preferredCountries: ['pt', 'gb', 'es'],
      separateDialCode: true,
      setNumber: true
    })

    $(this.element).find('.invalid-feedback').css("display", "block")
    $('#purchases-data').on('submit', () => {
      $(this.phoneNumberTarget).val(int.getNumber())
    })
  }
}
