import { Renderer } from '../../../../lib/dynamic_forms'
import SummaryTable from '../partials/summary_table'

$(document).on('turbo:load', () => {
  new SummaryTable().setup()

  if ($('#purchases-data, #purchases-data-processing').length !== 0) {
    $('[selector="dynamic_form"]').each((_index, $dynamicForm) => (new Renderer($dynamicForm)).render())

    const $countrySelects = $('#purchases_data_form_delivery_country_id, #purchases_data_form_billing_country_id')
    $countrySelects.on('change', ev => {
      const districtSelectEl = document.getElementById(ev.currentTarget.id.replace(/country/, 'district'))
      const districtSelectWrapperElement = districtSelectEl.parentElement
      if (ev.target.selectedOptions.length > 0 && ev.target.selectedOptions[0].text === 'Portugal') {
        districtSelectWrapperElement.style.display = 'block'
      } else {
        districtSelectWrapperElement.style.display = 'none'
        districtSelectEl.value = -1
        districtSelectEl.dispatchEvent(new Event('change'))
      }
    })
    $countrySelects.trigger('change')

    $('#copy_delivery_data').on('click', () => {
      const deliveryFields = ['name', 'address', 'phone', 'postal_code', 'nif', 'email', 'city']
      deliveryFields.forEach(field => {
        const deliveryValue = $('#purchases_data_form_delivery_' + field).val()
        $('#purchases_data_form_billing_' + field).val(deliveryValue)
        const errorMessage = $('#error-msg-purchases_data_form_billing_' + field)

        if (errorMessage !== '') {
          errorMessage.text('')
          $('#purchases_data_form_billing_' + field).removeClass('is-invalid')
        }
      })

      const selectDeliveryFields = ['district_id', 'country_id']
      selectDeliveryFields.forEach(field => {
        const deliveryValue = $('#purchases_data_form_delivery_' + field).val()
        $('#purchases_data_form_billing_' + field).val(deliveryValue).trigger('change')

        const errorMessage = $('#error-msg-purchases_data_form_billing_' + field)
        if (errorMessage !== '') {
          errorMessage.text('')
          $('#purchases_data_form_billing_' + field).removeClass('is-invalid')
        }
      })
    })

    $('input[type=checkbox]').each(function () {
      if ($("[selector='dynamic_form']").get(0)) {
        if ($("[selector='dynamic_form']").get(0).dataset.data) {
          for (const [key, value] of Object.entries(JSON.parse($("[selector='dynamic_form']").get(0).dataset.data))) {
            if (this.name.includes(key) && value === 'true') {
              this.checked = true
            }
          }
        }
      }
    })

    $('#checkout_steps_link').on('click', () => {
      $('#new_purchases_data_form').submit()
    })

    window.addEventListener('beforeunload', () => {
      $('#full_spinner').show()
    })
  }
})
