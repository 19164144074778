import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["totalPrice", "insuranceTotalPrice", "insuranceDiv", "commissionDiv", "commissionPrice"]

  connect () {
    this.element["cartResume"] = this;
  }

  update(cart) {
    if(cart.insurance_total > 0){
      this.insuranceDivTarget.style.display = "flex";
      this.insuranceTotalPriceTarget.innerText = String(cart.insurance_total.toFixed(2)).replace(".", ",")
    } else {
      this.insuranceDivTarget.style.display = "none";
    }
    this.totalPriceTarget.innerText = String(cart.total.toFixed(2)).replace(".", ",")
  }

  update_commission(comissionType, commissionPrice, cartTotalPrice){
    this.commissionDivTarget.style.display = "flex";
    const percentage = 1
    console.log(comissionType)
    if(comissionType == percentage){
      this.commissionPriceTarget.innerText = String(Number(commissionPrice)) + " %"
    } else {
      this.commissionPriceTarget.innerText = String(Number(commissionPrice).toFixed(2)).replace(".", ",") + " €"
    }
    this.totalPriceTarget.innerText = String(Number(cartTotalPrice).toFixed(2)).replace(".", ",")
  }
}
