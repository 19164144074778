import { Controller } from '@hotwired/stimulus'
import * as Routes from 'routes.js.erb'

export default class extends Controller {
  static targets = ["selectAll", "singleCheckbox" ]

  connect(){
    this.element["selectAllController"] = this;

    const allChecked = this.singleCheckboxTargets.map(checkbox => {
        return checkbox.dataset.checked === "true"
    })

    if(!allChecked.includes(false)){
      this.selectAllTarget.checked = true
    } else {
      this.selectAllTarget.checked = false
    }
  }

  select () {
    const activated = this.selectAllTarget.checked
    if(activated){
      this.singleCheckboxTargets.map(checkbox => {
        checkbox.checked = true
        $(checkbox).attr('data-checked', true)
      })
    } else {
      this.singleCheckboxTargets.map(checkbox => {
        checkbox.checked = false
        $(checkbox).attr('data-checked', false)
      })
    }

    if(this.element.dataset.type == "insurance"){
      this.updateCall()
    }
    
  }

  updateElement () {
    const allChecked = this.singleCheckboxTargets.map(checkbox => {
      return checkbox.checked ? true : false
    })

    if(!allChecked.includes(false)){
      this.selectAllTarget.checked = true
    } else {
      this.selectAllTarget.checked = false
    }
  }

  updateCall () {
    const activated = this.selectAllTarget.checked
    const elements = []
    this.singleCheckboxTargets.map(checkbox => {
      elements.push({[checkbox.dataset.ref]: activated})
    })

    const data = {
      insurance: JSON.stringify(elements)
    }
    
    $('#spinner').css({"visibility":"visible", "opacity": "1"});

    const $this = this
    $.ajax({
      type: 'POST',
      url: Routes.cart_insurance_path(),
      data: data,
      success: function (response) {
        if (response.state.state === 'success') {
          $('#cart_resume').get(0).cartResume.update(response.cart)
          $('#spinner').css({"visibility":"hidden", "opacity": "0"});
        } else {
          location.reload();
        }
      }
    })
  }
}
