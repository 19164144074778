import Swiper, { Navigation, Virtual, Pagination } from 'swiper'
import Cookies from 'js-cookie'

$(document).on('turbo:load', () => {
  if ($('#general-index').length !== 0) {
    $('.scroll-down').on('click', function () {
      $('html, body').animate({
        scrollTop: $('#homepage_scroll').offset().top - 40
      }, 1500)
    })

    $('#district_id').on('change', function (e) {
      Cookies.set('nearby_events', false)
      const $spinner = $('#spinner')
      const $nearbyEvents = $('#nearby_events')
      $nearbyEvents.css('display', 'none')
      $spinner.fadeIn()

      const interval = setInterval(function () {
        const nearbyEvents = Cookies.get('nearby_events')
        if (nearbyEvents == 'true') {
          $spinner.css('display', 'none')
          $nearbyEvents.fadeIn()
          clearInterval(interval)
        }
      }, 100)
    })
  }
  document.addEventListener('render_async_load', function (event) {
    if (event.container.get(0).id == '1') {
      setupSelects()
    }
  })
})
