$(document).on('turbo:load', () => {
  if ($('#cart-index').length !== 0) {
    $('#has_insurance').on('click', ev => {
      $(':checkbox').each(function () {
        this.checked = true
      })
      const $modal = $(ev.target).parents('.insurance-overlay')
      $modal.css('display', 'none')
    })

    $('[selector="close_insurance_modal"]').on('click', ev => {
      const $modal = $(ev.target).parents('.insurance-overlay')
      $modal.css('display', 'none')
    })

    $('#return_to_widget_event').on('click', () => {
      window.top.location.reload()
    })
    $('#finalize_purchase_button').on('click', () => {
      window.addEventListener('beforeunload', () => {
        $('#full_spinner').show()
      })
    })
  }
})
