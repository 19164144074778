import { ApplicationInsights } from '@microsoft/applicationinsights-web'

  const appInsights = new ApplicationInsights({
    config: {
      connectionString: "InstrumentationKey=12d23bb9-165e-4eb7-8db5-41fa57fa9772;IngestionEndpoint=https://francecentral-0.in.applicationinsights.azure.com/",
      disableCorrelationHeaders: false,
      disableFetchTracking: false,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      disableExceptionTracking: true,
      correlationHeaderExcludedDomains: ['myapp.azurewebsites.net', '*.queue.core.windows.net']
    }
  })
  appInsights.loadAppInsights()
  appInsights.trackPageView()

