$(document).on('turbo:load', () => {
  if ($('#purchases-payment').length !== 0 || $('#purchases-payment-processing').length !== 0) {
    $('.payment-card__radio_input').on('change', function () {
      var mbway = document.getElementById('purchases_payment_form_payment_method_mbway')
      var paypal = document.getElementById('purchases_payment_form_payment_method_paypal')
      var mb = document.getElementById('purchases_payment_form_payment_method_mb')
      var creditCard = document.getElementById('purchases_payment_form_payment_method_credit_card')

      $('#payment_method_error').css('display', 'none')

      if (mbway) {
        if (mbway.checked) {
          $('#mbway_phone_input').css('display', 'block')
          const comissionType = $('#mbway_commission_type').get(0).value
          const commissionPrice = $('#mbway_commission').get(0).value
          const cartTotalPrice = $('#mbway_cart_final_price').get(0).value
          $('#cart_resume').get(0).cartResume.update_commission(comissionType, commissionPrice, cartTotalPrice)
        } else {
          $('#mbway_phone_input').css('display', 'none')
        }
      }

      if (paypal) {
        if (paypal.checked) {
          $('#paypal_input').css('display', 'block')
          const comissionType = $('#paypal_commission_type').get(0).value
          const commissionPrice = $('#paypal_commission').get(0).value
          const cartTotalPrice = $('#paypal_cart_final_price').get(0).value
          $('#cart_resume').get(0).cartResume.update_commission(comissionType, commissionPrice, cartTotalPrice)
        } else {
          $('#paypal_input').css('display', 'none')
        }
      }

      if (mb) {
        if (mb.checked) {
          $('#mb_input').css('display', 'block')
          const comissionType = $('#mb_commission_type').get(0).value
          const commissionPrice = $('#mb_commission').get(0).value
          const cartTotalPrice = $('#mb_cart_final_price').get(0).value
          $('#cart_resume').get(0).cartResume.update_commission(comissionType, commissionPrice, cartTotalPrice)
        } else {
          $('#mb_input').css('display', 'none')
        }
      }
      if (creditCard) {
        if (creditCard.checked) {
          $('#credit_card_input').css('display', 'block')
          const comissionType = $('#creditCard_commission_type').get(0).value
          const commissionPrice = $('#creditCard_commission').get(0).value
          const cartTotalPrice = $('#creditCard_cart_final_price').get(0).value
          $('#cart_resume').get(0).cartResume.update_commission(comissionType, commissionPrice, cartTotalPrice)
        } else {
          $('#credit_card_input').css('display', 'none')
        }
      }
    })
    window.addEventListener('beforeunload', () => {
      $('#full_spinner').show()
    })
  }
})
