import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["button", "areaToBeDisplayed"]

  connect(){
    this.areaToBeDisplayedTarget.style.display = "none";
  }

  click(){
    this.areaToBeDisplayedTarget.style.display  == "none" ? this.show() : this.hide()
  }

  show() {
    this.areaToBeDisplayedTarget.style.display  = "block";
  }
  
  hide() {
    this.areaToBeDisplayedTarget.style.display = "none";
  }
}
